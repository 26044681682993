@import './styles/tailwind.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url('/public/assets/background.svg'); */
  /* background-size: cover;
  background-position: center; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Change Autocomplete styles in Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* border: 1px solid black !important; Ensure the border color */
  -webkit-text-fill-color: black !important; /* Set text color */
  /* -webkit-box-shadow: 0 0 0px 1000px pink inset !important; Change inset shadow to pink */
  box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0.05) inset !important; /* Change inset shadow to pink */
  transition: background-color 5000s ease-in-out 0s; /* Long transition */
  color: black !important; /* Ensure text color */
  opacity: 1 !important; /* Ensure the opacity is fully opaque */
}


/* Custom CSS to hide number input spinners */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* Add these styles to your CSS file */
/* Styles for the custom Intercom launcher */
 
.intercom-custom-launcher {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute; /* Change to absolute for header positioning */
  /* top: 30px; Position it at the top of the header */
  /* right: 0px; Align it to the right of the header */
  /* background-color: #ac24c4; Adjust the background color */
  color: white;
  /* border-radius: 30px; */
  /* padding: 10px 20px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  cursor: pointer;
  /* transition: background-color 0.3s ease; */
  z-index: 1000; /* Ensure the launcher appears above other elements */
}

.intercom-custom-launcher:hover {
  background-color: #ebcbff; /* Lighten on hover */
}

.chat-icon {
  font-size: 20px; /* Adjust the icon size */
  margin-right: 10px; /* Space between icon and text */
}

.chat-text {
  font-size: 16px; /* Adjust the text size */
  font-weight: bold;
}

.text-gradient {
  background: linear-gradient(90deg, 
    rgba(100, 238, 222, 1) 0%,  
    rgba(158, 83, 187, 1) 56%,
    rgba(49, 138, 221, 1) 100%   
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.background-gradient {
  background: linear-gradient(90deg, 
  rgba(158, 83, 187, 1) 13%,
  rgba(49, 138, 221, 1) 52%,
  rgba(100, 238, 222, 1) 100%
);
  background-size: 100%;
  background-repeat: no-repeat;
  -webkit-background-clip: border-box;
  background-clip: border-box;
}

.border-gradient {
  background: linear-gradient(90deg, rgba(100, 238, 222, 1), rgba(158, 83, 187, 1), rgba(49, 138, 221, 1));
  border-radius: 6px; /* Adjust for rounding */
  padding: 1px; /* Border thickness */
  display: inline-block; /* Keep the content size */
}

