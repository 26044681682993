/* tailwind.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer utilities {
    .gradient-spinner {
      background: conic-gradient(
        from 90deg,
        rgba(100, 238, 220, 1),
        rgba(158, 83, 187, 1),
        rgba(49, 138, 221, 1),
        rgba(100, 238, 220, 0)
      );
      mask-image: radial-gradient(circle, transparent 46%, black 46%);
      animation: spin 1.5s linear infinite;
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }